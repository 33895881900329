import { BlogTitle } from "@/components/BlogTitle/BlogTitle.tsx";
import { Page } from "@/components/Page.tsx";
import ReactSvgGraphics from "@/components/toys/react-svg-graphics/ReactSvgGraphics.tsx";
import "@/styles/Blog.scss";
import DriveclearDemoV1 from "../../../../src/pages/blog/2023/May/driveclear-demo-v1.gif";
import * as React from 'react';
export default {
  BlogTitle,
  Page,
  ReactSvgGraphics,
  DriveclearDemoV1,
  React
};