import { Link } from "gatsby";
import { BiMailSend } from "react-icons/bi";
import { Page } from "@/components/Page.tsx";
import avatarDarkmode from "@/images/avatar-darkmode.svg";
import avatarDefault from "@/images/avatar-default.svg";
import github from "@/images/github.svg";
import linkedin from "@/images/linkedin.svg";
import "@/styles/Landing.scss";
import * as React from 'react';
export default {
  Link,
  BiMailSend,
  Page,
  avatarDarkmode,
  avatarDefault,
  github,
  linkedin,
  React
};