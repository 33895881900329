exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-2019-mar-vehicle-clearance-mdx": () => import("./../../../src/pages/blog/2019/Mar/vehicle-clearance.mdx" /* webpackChunkName: "component---src-pages-blog-2019-mar-vehicle-clearance-mdx" */),
  "component---src-pages-blog-2021-nov-fsharp-json-parser-mdx": () => import("./../../../src/pages/blog/2021/Nov/fsharp-json-parser.mdx" /* webpackChunkName: "component---src-pages-blog-2021-nov-fsharp-json-parser-mdx" */),
  "component---src-pages-blog-2022-apr-data-warehousing-finances-mdx": () => import("./../../../src/pages/blog/2022/Apr/data-warehousing-finances.mdx" /* webpackChunkName: "component---src-pages-blog-2022-apr-data-warehousing-finances-mdx" */),
  "component---src-pages-blog-2023-may-react-svg-graphics-mdx": () => import("./../../../src/pages/blog/2023/May/react-svg-graphics.mdx" /* webpackChunkName: "component---src-pages-blog-2023-may-react-svg-graphics-mdx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */)
}

